<template>

	<div class="bottom-footer align-center">
		<div class="container">
			<span
				class="copy"
				v-html="footerCopy"></span>
			<span class="links">
				<ul>
					<li
						v-for="item in footerLinks"
						:key="item.link">
						<router-link :to="item.link">{{ item.text }}</router-link>
					</li>
				</ul>
			</span>
		</div>
	</div>

</template>

<style type="text/css" lang="scss">
.bottom-footer {
	font-size: 12px;
	color: #999999;
	span {
		display: inline-block;
		padding: 5px 20px;
	}

	.links {
		ul {
			margin: 0;
			padding: 0;
			li {
				list-style-type: none;
				display: inline-block;
				a {
					display: block;
					padding: 5px;
					color: #888888;
					text-decoration: none;
				}
			}
		}
	}
}
</style>

<script>
export default {
	data() {
		return {
			name: 'footer',
			footerCopy:
				'&copy; Copyright new Date().getFullYear(). All Rights Reserved.',
			footerLinks: [
				{ text: 'Join Our Network', link: '/join-our-network' },
				{ text: 'Contact Us', link: '/contact-us' },
				{ text: 'Privacy policy', link: '/privacy-policy' },
			],
		};
	},
};
</script>
