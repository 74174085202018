import Vue from 'vue';

/* Add ireloApi as global variable to Vue */
import IreloApiHelper from '@/helpers/IreloApi';

const ireloApi = {
	install: function(Vue) {
		Vue.$ireloApi = new IreloApiHelper({
			version: 'v2',
		});

		Vue.prototype.$ireloApi = Vue.$ireloApi;
	},
};

Vue.use(ireloApi);
