<template>
	<span
		:data-cy-tokens="JSON.stringify(quote.tokens)"
		class="lead-stream-data"
	>
	</span>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		showdata: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState({
			quote: state => state.quote,
		}),
	},
	created() {
		if (this.$route.query.external) {
			this.$store.commit('TOGGLE_IFRAME', this.$route.query.external);
		}
	},
};
</script>

<style type="text/css">
.lead-stream-data pre {
	background: #efefef;
	color: #555555;
	padding: 10px;
	font-size: 11px;
}
</style>
