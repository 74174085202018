<template>
	<div class="sticky-container">
		<PageHeader v-if="!isIframe" />

		<div class="contents">
			<div class="container">
				<nuxt />
			</div>
		</div>

		<PageFooter v-if="!isIframe" />
	</div>

</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import PageFooter from '../components/footer.vue';

export default {
	components: {
		PageHeader,
		PageFooter,
	},
	data() {
		return {
			name: 'main-page',
		};
	},
	computed: {
		isIframe() {
			return this.$store.state.iframeFlag !== null;
		},
	},
};
</script>
