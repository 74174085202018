<template>
	<div
		v-if="casList.length"
		class="modal-mask"
	>
		<div class="modal-wrapper">
			<div class="modal-container">
				<header
					v-if="showHeader"
					class="modal-header"
				>
					<div class="modal-title">
						<h1>Call Now for a Free Quote!</h1>
					</div>
					<div class="modal-close">
						<a
							href="#"
							@click.prevent="closeModal"
						>
							x
						</a>
					</div>
				</header>
				<div class="modal-inside">
					<detect-network @detected-condition="onNetworkChange">
						<div
							slot="offline"
							class="subheadline"
						>
							<p>You are currently offline and we are unable to display the list of qualified moving companies.</p>
							<p>Please reconnect your network and try again.</p>
						</div>
						<div slot="online">
							<div
								class="headline"
							>
								<p>
									Instead of requesting an online quote, <span class="helper-text">call several</span> transporters directly to compare
									pricing. All transport companies listed below service the entire USA!
								</p>
							</div>
							<div
								v-if="loadingCasList"
								class="loading"
							>
								<i class="fa fa-refresh fa-spin"></i>
							</div>
							<div
								v-for="company in casList"
								:key="company.name"
								class="company-list"
							>
								<a
									:href="`tel:${company.number}`"
									class="row link"
									@click="onClickShipper"
								>
									<div class="col-xs-12 col-sm-3">
										<img :src="company.logo" >
									</div>
									<div class="col-xs-12 col-sm-9">
										<div class="row">
											<div class="col-xs-6">
												<div class="company-name">{{ company.name }}</div>
												<div
													class="company-score"
												>
													<i
														v-for="rating in 5"
														:key="rating"
														:class="{
															'fa-star': rating <= Number(company.rating),
															'fa-star-o': rating > Number(company.rating)
														}"
														class="fa"
													/>
												</div>
											</div>
											<div class="col-xs-6">
												<span class="company-phone">
													<i class="fa fa-phone"></i>
													{{ phoneFormat(company.number) }}
												</span>
											</div>
										</div>
									</div>
								</a>
							</div>
						</div>
					</detect-network>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import detectNetwork from 'v-offline';

export default {
	components: {
		detectNetwork,
	},

	props: {
		showHeader: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			casList: [],
			isOffline: false,
			loadingCasList: false,
		};
	},

	mounted() {
		this.loadingCasList = false;
	},
	methods: {
		closeModal() {
			this.$emit('modal-close');
		},
		onClickShipper() {
			if (window.analytics) {
				window.analytics.track('Conversion_Calls');
			}

			window._vis_opt_queue = window._vis_opt_queue || [];
			window._vis_opt_queue.push(function() {
				window._vis_opt_goal_conversion(211);
			});
		},
		onNetworkChange(isOnline) {
			this.$log.debug(isOnline, this.isOffline);

			// Only attempt a navigation if the user was offline and came
			// back online, otherwise it will try to navigate if this is
			// displayed because of actual error.
			if (this.isOffline && isOnline) {
				this.$router.push({ name: 'origin' });
			}

			this.isOffline = !isOnline;
		},
		phoneFormat(number) {
			const s = number;
			const s2 = (String(s)).replace(/\D/g, '');
			let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);

			m = !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];

			return m;
		},
	},
};
</script>

<style lang="scss">
.modal-header {
	display: flex;
	flex-direction: row;

	.modal-title {
		border-bottom: 1px solid #D8DFE7;
		flex: 2 0 auto;
		margin-right: 2em;
	}

	.modal-close {
		flex: 0 1 auto;
		font-size: 22px;

		a {
			color: #000000;
			text-decoration: none;
		}
	}
}

.modal-inside {
	.headline {
		margin: 0 auto;
		max-width: 90%;

		.helper-text {
			font-size: inherit;
		}
	}
}

.company-list {
	margin-bottom: 1rem;
	text-align: left;

	.link {
		align-items: center;
		display: flex;
		text-decoration: none;

		&:hover {
			.company-phone {
				text-decoration: underline;
			}
		}
	}

	img {
		display: block;
		margin: 0 auto;
	}

	.company-name {
		color: #1689e5;
		font-weight: bold;
		font-size: 18px;
	}

	.company-score {
		color: #ffd100;
	}

	.company-phone {
		display: block;
		padding: 10px;
		text-align: right;
		color: #1689e5;
	}
}

.loading {
	font-size: 4rem;
	color: #999999;

	i {
		color: #1689e5;
	}
}
</style>
