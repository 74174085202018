<template>
	<div id="layout-form">
		<div class="form-page container-fluid">
			<div
				:style="`width: ${stepMaker}%;`"
				class="step-maker"
			>
			</div>
			<div
				v-if="!isIframe"
				id="step-logo"
				class="logo"
			>
				<nuxt-link to="/secure">
					<img
						src="/images/logo.png"
						alt="Calculate Moving Quotes"
						title="TopMoving.com"
						width="155"
						height="35"
					/>
				</nuxt-link>
			</div>

			<div class="container">
				<div
					id="main-form"
					class="form-container"
				>
					<div
						v-if="!quote.isContinue"
						class="steps-counter"
					>
						{{ stepsCount }} / {{ stepsTotal }}
					</div>

					<div class="row">
						<div :class="formClasses">
							<div>
								<nuxt
									:class="{ proceed }"
									class="hide-form"
								/>
								<div
									v-if="!proceed"
									class="loading-form"
								>
									<i class="fa fa-refresh fa-spin"></i> Loading Form
								</div>
							</div>
						</div>

						<div
							v-if="!quote.isContinue"
							class="col-xs-12 col-sm-1"
						>
							<ul
								v-if="steps"
								class="steps-navigation"
							>
								<li
									v-for="item in steps"
									:key="item.link"
								>
									<nuxt-link
										v-if="isActive(item)"
										:to="item.link"
										class="active"
									>
										<span>{{ item.text }}</span>
										<i class="fa fa-circle"></i>
									</nuxt-link>
									<a v-else>
										<span>{{ item.text }}</span>
										<i class="fa fa-circle"></i>
									</a>
								</li>
							</ul>
						</div>
						<div
							v-else
							class="col-xs-12 col-sm-4"
						>
							<img
								src="@/static/images/quote-blur.png"
								alt="Quote estimate blurry"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="!isIframe"
			class="sub-footer"
		>
			<div class="container">
				<div
					v-if="privacyMessage"
					class="privacy-message"
					v-html="privacyText"
				>
				</div>
				<div class="copyright">
					<span v-html="footerCopy"></span>
				</div>
			</div>
		</div>

		<streaming></streaming>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import streaming from '../components/streaming.vue';

export default {
	components: {
		streaming,
	},
	data() {
		return {
			name: 'form-layout',
			showCASModal: false,
			stepMaker: {},
			slug: null,
			stepsTotal: null,
			stepsCount: null,
			/* eslint-disable quotes */
			privacyText:
				"<strong>Your privacy is important and your information is protected by 256 bit encryption!</strong> By entering your phone number and clicking 'Continue', you give express written consent to be quoted by our network of transporters which may be through an automated dialer, even if you are on a DNC (do not call) list.",
			/* eslint-enable quotes */
			privacyMessage: false,
			footerCopy:
				'&copy; Copyright '
				+ new Date().getFullYear()
				+ '. All Rights Reserved.',
			footerLinks: [
				{ text: 'Join Our Network', link: '/join-our-network' },
				{ text: 'Contact Us', link: '/contact-us' },
				{ text: 'Privacy policy', link: '/privacy-policy' },
			],
			proceed: false,
		};
	},
	computed: {
		...mapState({
			quote: state => state.quote,
			steps: state => state.steps,
		}),
		formClasses() {
			let classes = 'col-xs-12 col-sm-';

			// Need to leave space for image to be displayed on right side
			if (this.quote.isContinue) {
				classes += '8';
			} else {
				classes += '11';
			}

			return classes;
		},
		isIframe() {
			return this.$store.state.iframeFlag !== null;
		},
	},
	watch: {
		/* eslint-disable no-unused-vars */
		$route(to, from) {
			this.stepCounter();
		},
		/* eslint-enable */
	},
	mounted() {
		this.checkfortokens();
		this.stepCounter();

		this.$root.$on('moveTo', stepName => {
			const location = this.$store.state.steps[stepName].link;

			setTimeout(() => {
				this.$router.push(location);
			}, 100);
		});

		this.$root.$on('saveUserData', data => {
			this.$store.dispatch('quote/update', data);
		});

		window.addEventListener('resize', () => {
			this.windowPosition();
		});

		this.$nextTick(() => {
			this.windowPosition(true);
		});

		document.addEventListener('mousemove', this.mouseleave);
	},
	beforeDestroy() {
		document.removeEventListener('mousemove', this.mouseleave);
	},
	methods: {
		checkfortokens() {
			const autoToken = this.quote.tokens.move || null;
			let tokenAttempt = this.$session.get('tokenAttempt') || 0;

			// but why?
			if (!autoToken && tokenAttempt < 5) {
				setTimeout(() => {
					tokenAttempt++;
					this.$session.set('tokenAttempt', tokenAttempt, 10);

					window.location.reload();
				}, 2000);
			} else if (tokenAttempt >= 5) {
				this.$nuxt.error({
					statusCode: 400,
					message: 'Token validation failed',
				});
			} else {
				this.proceed = true;

				return true;
			}

			return false;
		},
		isActive(step) {
			const currentStep = this.$store.getters['steps/currentStep'](this.$route.name) || {};

			try {
				if (step.step <= currentStep.step) {
					return true;
				}
			} catch (err) {
				this.$log.warn(err);
			}

			return false;
		},
		mouseleave(evt) {
			if (this.stepsCount > 7 && evt.clientY < 5) {
				this.showCASModal = true;
			}
		},
		windowPosition(staticvar) {
			const check
				= this.$store.state.iframeFlag === null
				&& document.getElementById('step-logo');

			if (check) {
				const mainForm = document.getElementById('main-form');
				const mainFormHeight = mainForm.clientHeight;
				const stepLogo = document.getElementById('step-logo');
				const stepLogoHeight = stepLogo.clientHeight;
				const windowHeight = window.outerHeight;

				let paddingTop
					= (windowHeight - (mainFormHeight + stepLogoHeight)) / 2;

				if (staticvar === true || this.quote.isContinue) {
					paddingTop -= stepLogoHeight;
				}

				paddingTop -= 50;

				mainForm.setAttribute(
					'style',
					'padding-top: ' + paddingTop + 'px;'
				);
			}
		},
		stepCounter() {
			if (this.$route.name === 'phone') {
				this.privacyMessage = true;
			} else {
				this.privacyMessage = false;
			}

			if (this.steps[this.$route.name]) {
				this.stepsTotal = Object.keys(this.steps).length;
				this.stepsCount = this.steps[this.$route.name].step;

				if (this.stepsCount !== 9) {
					// Reset the 'isContinue' flag if the user navigated away from phone page
					this.$store.commit('quote/UPDATE', { isContinue: false });
				}

				this.stepMaker
					= (this.stepsCount / (this.stepsTotal + 1)) * 100;
			}
		},
	},
};
</script>

<style lang="scss">
.headline {
	margin-bottom: 25px;
	font-size: 22px;
}

.hide-form,
.hideForm {
	display: none;
	&.proceed {
		display: block;
	}
}
.loading-form {
	text-align: center;
	font-size: 18px;
	color: #999999;
	i {
		color: #1689e5;
	}
}
.sub-footer {
	bottom: 0;
	color: #cccccc;
	font-size: 11px;
	margin-top: 5%;
	position: fixed;
	text-align: center;
	width: 100%;

	.privacy-message {
		font-size: 10px;
		margin-bottom: 10px;
		padding: 0 20px;
		strong {
			color: #999999;
		}
	}

	.copyright {
		padding-bottom: 5px;
	}

	ul {
		display: inline-block;
		li {
			list-style-type: none;
			display: inline-block;
			a {
				display: block;
				padding: 2px 5px;
				color: #cccccc;
				text-decoration: none;
			}
		}
	}
}
</style>
