import Vue from 'vue';

import { isEmpty } from 'lodash-es';

export default ({ store, redirect, route }) => {
	const slug = route.name;
	let redirectName = slug;
	let storeProp = null;

	Vue.$log.debug('valid-route | ', slug, route, store.state.quote);

	if (['origin', 'secure', 'index'].includes(slug)) {
		return;
	}

	switch (slug) {
		case 'destination':
			redirectName = 'origin';
			break;

		case 'move-date':
			redirectName = 'destination';
			break;

		case 'first-name':
			redirectName = 'move-date';
			storeProp = 'moveDate';
			break;

		case 'last-name':
			redirectName = 'first-name';
			storeProp = 'firstName';
			break;

		case 'email':
			redirectName = 'last-name';
			storeProp = 'lastName';
			break;

		case 'phone':
			redirectName = 'email';
	}


	if (!storeProp) {
		storeProp = redirectName;
	}

	if (slug !== redirectName && isEmpty(store.state.quote[storeProp])) {
		redirect({ name: redirectName });
	}

	return true;
};
