import Vue from 'vue';

export const state = () => ({
	keyword: '',
	source: '',
	platform: '',
	referrer: '',
	tokens: {
		auto: '',
		move: '',
	},
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	moveDate: '',
	size: '',
	origin: {},
	destination: {},
	isContinue: false,
	textId: null,
});

export const getters = {
	locationSplit: state => location => {
		const locationObj = state[location];

		return {
			[location]: locationObj.value,
			[`${location}_city`]: locationObj.city,
			[`${location}_state`]: locationObj.state,
			[`${location}_zip`]: locationObj.zipcode,
		};
	},
};

export const actions = {
	clear() {
		try {
			this._vm.$session.destroy();
		} catch (err) {
			this._vm.$log.warn(err);
		}
	},

	async init({ dispatch, state }, routeData) {
		// 'query' comes from the $route object
		const urlQuery = routeData.query;

		try {
			const browserData = {
				...{},
				...{
					keyword: '',
					platform: window.navigator.platform || '',
					referrer: document.referrer || window.location.href,
					source: 'TM',
				},
				...urlQuery,
			};

			const quoteFromStorage = this._vm.$session.get('quote');

			// Equivalent to using Object.assign({}, ...)
			const leadData = {
				...{},
				...state,
				...browserData,
				...quoteFromStorage,
			};

			// If there is no leadToken in the user's storage we need to generate new lead id
			if (!leadData.tokens.move) {
				try {
					await dispatch('generateLeadToken', {
						type: 'move',
					});

					await dispatch('generateLeadToken', {
						type: 'auto',
					});
				} catch (err) {
					return Promise.reject(err);
				}
			}

			return await dispatch('update', leadData);
		} catch (err) {
			return Promise.reject(err);
		}
	},

	async generateLeadToken({ commit }, payload) {
		let token = process.env.NUXT_ENV_TOKEN_MOVE;

		if (!payload.type) {
			return Promise.reject('ERROR: Missing "type" property!');
		}

		if (payload.type === 'move') {
			token = process.env.NUXT_ENV_TOKEN_MOVE;
		}

		const apiData = {
			'valet-token': token,
		};

		try {
			const res = await this._vm.$ireloApi.post('/hello', apiData);

			if (res.status !== 200 || !res.data) {
				throw new Error('Failed to generate lead token.' + res.data);
			}

			const leadToken = res.data.lead_token;

			// Ensure that we have a leadToken for the user
			if (!leadToken) {
				throw new Error('Empty leadToken!');
			}

			if (payload.type === 'move' && window.analytics) {
				window.analytics.identify(leadToken, null);
			}

			commit('SET_TOKEN', {
				type: payload.type,
				leadToken,
			});

			return Promise.resolve(leadToken);
		} catch (err) {
			return Promise.reject(err);
		}
	},

	getExistingLeadData({ commit }, payload) {
		let leadData = {};
		const leadToken = payload.leadToken;
		const valetToken = process.env.NUXT_ENV_TOKEN_MOVE;

		return new Promise((resolve, reject) => {
			this._vm.$ireloApi
				.get(`/lead/${leadToken}?valet-token=${valetToken}`)
				.then(resp => {
					this._vm.$log.info(resp);
					leadData = resp.data;

					leadData.isContinue = true;

					if (leadData.hasOwnProperty('leadToken')) {
						commit('SET_TOKEN', { type: 'move', ...leadData });
					}

					commit('UPDATE', leadData);

					resolve(leadData);
				})
				.catch(resp => {
					this._vm.$log.error(resp);
					reject(resp);
				});
		});
	},

	update({ commit, state }, payload) {
		const newQuote = Object.assign({}, state, payload);

		try {
			commit('UPDATE', newQuote);

			return new Promise((resolve, reject) => {
				try {
					this._vm.$session.set('quote', newQuote);
					resolve(newQuote);
				} catch (err) {
					reject(err);
				}
			});
		} catch (err) {
			this._vm.$log.error(err);
			throw new Error(err);
		}
	},
};

export const mutations = {
	SET_TOKEN: (state, payload) => {
		return Vue.set(state.tokens, payload.type, payload.leadToken);
	},
	UPDATE: (state, payload) => {
		for (const prop in payload) {
			if (state.hasOwnProperty(prop)) {
				state[prop] = payload[prop];
			} else {
				Vue.$log.warn(`'${prop}' is not defined in state!`);
			}
		}
	},
};
