
export const state = () => ({
	iframeFlag: null,
	isDebug: process.env.NUXT_ENV_DEBUG,
});

export const actions = {};
export const getters = {};
export const mutations = {
	TOGGLE_IFRAME(state, flag) {
		state.iframeFlag = flag;
	},
};
