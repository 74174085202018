<template>
	<div class="container">
		<div v-if="error.statusCode === 404">
			<h1>Page not found</h1>
			<router-link to="/">
				Return to Home
			</router-link>
		</div>
		<div
			v-else
			class="error-modal"
		>
			<CallAutoShippers
				:show-header="false"
			>
				<p slot="headline">Sorry, it looks like your browser isn't supported. <br > We would love to help you find a <strong>qualified moving company</strong>.</p>
			</CallAutoShippers>
		</div>
	</div>
</template>

<script>
import CallAutoShippers from '../components/CallAutoShippers.vue';

export default {
	components: {
		CallAutoShippers,
	},
	props: {
		error: {
			type: Object,
			default: function() {
				return {
					statusCode: 400,
					message: 'ERROR',
				};
			},
		},
	},
	data() {
		return {};
	},
	head() {
		return {
			title: 'Whoops - Nothing Here',
			meta: [
				{
					hid: 'description',
					name: 'description',
					content: '404 Page Not Found',
				},
			],
		};
	},
	mounted() {
		if (this.error) {
			this.$log.error('ERROR: ', this.error);
		}
	},
};
</script>
