import { filter, find } from 'lodash-es';

export const state = () => ({
	'origin': {
		name: 'origin',
		link: '/origin',
		text: 'Vehicle Origin',
		step: 1,
		active: false,
	},
	'destination': {
		name: 'destination',
		link: '/destination',
		text: 'Vehicle Destination',
		step: 2,
		active: false,
	},
	'size': {
		name: 'size',
		link: '/size',
		text: 'Move Size',
		step: 3,
		active: false,
	},
	'move-date': {
		name: 'move-date',
		link: '/move-date',
		text: 'Move Date',
		step: 4,
		active: false,
	},
	'first-name': {
		name: 'first-name',
		link: '/first-name',
		text: 'First Name',
		step: 5,
		active: false,
	},
	'last-name': {
		name: 'last-name',
		link: '/last-name',
		text: 'Last Name',
		step: 6,
		active: false,
	},
	'email': {
		name: 'email',
		link: '/email',
		text: 'Email Address',
		step: 7,
		active: false,
	},
	'phone': {
		name: 'phone',
		link: '/phone',
		text: 'Phone Number',
		step: 8,
		active: false,
	},
});

export const getters = {
	activeSteps: state => {
		return filter(state, { active: true });
	},

	currentStep: state => name => {
		return find(state, { name });
	},
};

export const actions = {};

export const mutations = {};
