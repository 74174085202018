import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _67c87980 = () => interopDefault(import('../pages/continue-quote.vue' /* webpackChunkName: "pages/continue-quote" */))
const _6b3ac9d8 = () => interopDefault(import('../pages/destination.vue' /* webpackChunkName: "pages/destination" */))
const _a83e53bc = () => interopDefault(import('../pages/email.vue' /* webpackChunkName: "pages/email" */))
const _631424f2 = () => interopDefault(import('../pages/first-name.vue' /* webpackChunkName: "pages/first-name" */))
const _86387030 = () => interopDefault(import('../pages/last-name.vue' /* webpackChunkName: "pages/last-name" */))
const _fab706a0 = () => interopDefault(import('../pages/move-date.vue' /* webpackChunkName: "pages/move-date" */))
const _13f21690 = () => interopDefault(import('../pages/origin.vue' /* webpackChunkName: "pages/origin" */))
const _1faee218 = () => interopDefault(import('../pages/phone.vue' /* webpackChunkName: "pages/phone" */))
const _0a067801 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _3c699b3e = () => interopDefault(import('../pages/secure.vue' /* webpackChunkName: "pages/secure" */))
const _4de7a32a = () => interopDefault(import('../pages/size.vue' /* webpackChunkName: "pages/size" */))
const _915d037c = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/continue-quote",
      component: _67c87980,
      name: "continue-quote"
    }, {
      path: "/destination",
      component: _6b3ac9d8,
      name: "destination"
    }, {
      path: "/email",
      component: _a83e53bc,
      name: "email"
    }, {
      path: "/first-name",
      component: _631424f2,
      name: "first-name"
    }, {
      path: "/last-name",
      component: _86387030,
      name: "last-name"
    }, {
      path: "/move-date",
      component: _fab706a0,
      name: "move-date"
    }, {
      path: "/origin",
      component: _13f21690,
      name: "origin"
    }, {
      path: "/phone",
      component: _1faee218,
      name: "phone"
    }, {
      path: "/privacy-policy",
      component: _0a067801,
      name: "privacy-policy"
    }, {
      path: "/secure",
      component: _3c699b3e,
      name: "secure"
    }, {
      path: "/size",
      component: _4de7a32a,
      name: "size"
    }, {
      path: "/thank-you",
      component: _915d037c,
      name: "thank-you"
    }, {
      path: "/",
      component: _0547af50,
      name: "index"
    }],

    fallback: false
  })
}
