// import { config } from '@/config';
import axios from 'axios';

export default class IreloApi {
	constructor(options = {}) {
		this.host = options.host || `${process.env.NUXT_ENV_API_VALET}`;
		this.version = options.version || '';
		this.url = `//${this.host}/api/${this.version}`;

		this.http = axios.create({
			baseURL: this.url,
			timeout: 15000,
			withCredentials: false,
		});
	}

	post(path, data) {
		return this.http.post(path, data);
	}

	get(path = '') {
		return this.http.get(path);
	}
}
