import Vue from 'vue';

export default ({ error, store, redirect, route }) => {
	Vue.$log.info('route -> ', route);

	if (route.name === 'thank-you') {
		return true;
	}

	if (!sessionStorage || !localStorage) {
		error({
			statusCode: 500,
			message: 'Cannot read sessionStorage or localStorage',
		});
	}

	const continueLeadToken = route.query['lead-token'];

	if (route.query.external) {
		store.commit('TOGGLE_IFRAME', route.query.external);
	}

	if (continueLeadToken) {
		return true;
	} else if (!store.state.quote.tokens.move) {
		store.dispatch('quote/init', route)
			.then(() => {
				if (!route.name === 'origin') {
					return redirect({ name: 'origin' });
				}
			})
			.catch(err => {
				error({
					statusCode: 500,
					message: err,
				});
			});
	}
};
