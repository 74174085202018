<template>
	<div class="fixed-header container headerbg">
		<div class="row middle-xs">
			<div class="col-xs-12 center-xs">
				<div class="logo">
					<router-link to="/secure">
						<img
							src="/images/logo.png"
							alt="Calculate Moving Quotes"
							title="TopMoving.com"
							width="155"
							height="35"
						/>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import '../assets/sass/variables';

.logo {
	margin-left: 12px;
}
</style>

<script>
export default {
	name: 'PageHeader',
	data() {
		return {};
	},
};
</script>
